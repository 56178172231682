* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Montserrat, Roboto !important;
}
.blog-page p{
  margin: 15px 0px;
}
img {
  max-width: 100%;
  height: auto;
}

.App {
  min-height: 100vh;
  width: 100vw;
  max-width: 1900px;
  margin: 0rem auto;
  overflow: hidden;
}

body {
  max-width: 100%;
  overflow-x: hidden;
  background-color: #ffffff;
  line-height: 1.9;
}

.head-one {
  text-align: left;

  font-size: 2.88rem;
  color: #244886;
  margin-bottom: 1rem
}

.head-on {

  font-size: 2.88rem;
  color: #244886;
  margin-bottom: 0.5rem
}

.one-body {
  text-align: left;

  font-size: 1.5rem;
  color: #000000;
}

.head-two {
  text-align: left;

  font-size: 1.75rem;
  color: #000000;
  margin-bottom: 1.5rem
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 75px;
  border-bottom: 3px solid #d1d1d1;
  background-color: #ffffff;
  padding-left: 3.25rem;
  padding-right: 3.25rem;
}

.header-sam-two {

  font-size: 1.6rem;
  font-weight: 600;
  color: #000000;
  margin-bottom: 0.8rem
}

.blog-summary {

  font-size: 0.9rem;
  font-weight: 400;
  color: #000000;
  margin-top: 0.8rem;
}

.new-sms {
  /*font-family: "Space Grotesk";*/
  color: #244886;
  font-size: 1.3rem;
  font-weight: 700;
  margin-top: 0.75rem;
  cursor: pointer
}

.header-sam-three {
  font-size: 2.25rem;
  font-weight: 700;
  margin-bottom: 1.35rem;
  color: #000000;
}

.header-sam-three-p {

  font-size: 1.35rem;
  font-weight: 400;
}

.home-contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
}



.abouts-contents {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #ffffff;
}

.abouts-blank {
  min-height: 20vh;
  background-color: #F7F6F7;
  width: 100%;
}

.home-container {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.hold-container {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.labow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.labe {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 40%;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #e0e0e0;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-track:hover {
  background: #555;
}

.service-container {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  /* align-content: ; */
}

.log-lab {
  /*font-family: "poppins";*/
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
  font-weight: 500;
}

.log-email {
  border: 0.75px solid #ccc9c9;
  border-radius: 5px;
  padding: 0.65rem;
  background-color: #e9eef1;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 1px 1.5px rgba(0, 0, 0, 0.24);
  margin-bottom: 1.25rem;
}

.log-pass {
  border: none;
  background-color: inherit;
  width: 100%;
}

.log-pass:focus {
  border: none;
  background-color: inherit;
  width: 100%;
  border: transparent;
  outline: none !important;
}

.pass-div {
  border: 0.75px solid #ccc9c9;
  border-radius: 5px;
  padding: 0.65rem;
  background-color: #e9eef1;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 1px 1.5px rgba(0, 0, 0, 0.24);
  margin-bottom: 1.15rem;
  display: flex;
}

.pass-div img {
  width: 10%;
  max-width: 10%;
  height: 1.25rem;
}

.loga-head {
  color: #10069b;
  text-align: right;
  margin-bottom: 2rem;
}

.login-head {
  /*font-family: "IBM Plex Sans ";*/
  font-size: 1.85rem;
  margin-bottom: 3rem;
  color: #244886;
}

.login-heads {
  /*font-family: "IBM Plex Sans ";*/
  font-size: 1.12rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.login-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 91.5%;
  max-width: 100%;
  background-color: #e6eaf0;
  margin: 2rem auto;
  min-height: 80vh;
  border-radius: 10px;
  justify-content: flex-start;
  padding: 3rem;
}

.login-container {
  background-color: #f6f8fa;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  padding: 0;
}


.careers-container {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.blog-container {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.blog-case {
  width: 100%;
  max-width: 100%;
  display: flex;
  margin-bottom: 3rem;
}

.blog-case-left {
  width: 90%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 4rem;
  padding-top: 2rem;
  margin: 0px auto;
}

.trends {
  font-size: 1rem;
  margin-top: 1rem;
  font-weight: 600;
}

.minitrends {
  font-size: 0.7rem;
  margin-top: 0.25rem;
  font-weight: 400;
}

.blog-case-right {
  width: 32%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  border-left: .25px solid #e6e1e1;
  padding: 2.2rem;
  align-items: left;
  justify-content: flex-start;
}

.blogs-container {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: ; */
}

.contacts-container {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.service-contents {
  min-height: 60vh;
  margin-top: 2rem;
  background-color: #ffffff;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-bottom: 4rem;
}

.eliminate {

  font-size: 1.1rem;
  font-weight: 6\00;
  color: #000;
  margin-bottom: 1rem
}

.eliminate-white {

  font-size: 1.1rem;
  font-weight: 500;
  color: #000000;
  margin-bottom: 1rem
}

.eliminate-white-mini {

  font-size: 0.9rem;
  font-weight: 500;
  color: #000000;
  margin-bottom: 1rem
}

.blog-contents {
  min-height: 30vh;
  margin-top: 0rem;
  background-color: #F7F6FF;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10vh;
}

.blogs-header {
  line-height: 1.3;
  font-size: 2.75rem;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}


.contact-contents {
  min-height: 80vh;
  padding-top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.contact-contents h2 {
  font-size: 4.3rem;
  font-weight: 600;
}

.contact-body {
  /* margin-top: 0.8rem; */
  width: 48%;
  max-width: 50%;
  color: #000000;
  font-size: 0.88rem;
  text-align: center;
}

.contact-form {
  margin-top: 7.5vh;
  margin-bottom: 6vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
  width: 45%;
  max-width: 50%;
}

.contact-form input[type=text] {
  border: 1px solid;
  border-color: #E0E0E0;
  width: 100%;
  max-width: 100%;
  padding: 0.9rem;
  margin-bottom: 0.8rem;
  text-align: left;
}

.header-five {
  /*font-family: "Space Grotesk";*/
  color: #010101;
  font-size: 0.81rem;
  font-weight: 300;
  margin-left: 1.5rem;
}

.contact-sub {
  margin-top: 0.8rem;
  background-color: #244886;
  border-style: none;
  height: 3rem;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
}

.contact-sum {
  margin-top: 0.8rem;
  background-color: #9fb2d1;
  border-style: none;
  height: 3rem;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
}



.career-form input[type=submit] {
  margin-top: 0.8rem;
  background-color: #244886;
  border-style: none;
  height: auto;
  color: #ffffff;
  font-size: 1rem;
  width: auto;
  padding: 1rem 2rem;
  margin: 2rem auto;
  margin-bottom: 0;
  font-weight: 500;
  border-radius: 5px;
}

.over-head {
  color: #244886;

  font-size: 1.4rem;
  font-weight: 600;
}

.man {
  width: 4.25rem;
  height: 4.5rem;
}

.memo {
  width: 4.25rem;
  height: 4.5rem;
  /* margin-bottom: .74rem; */
  margin-left: 1.5rem;
}

.career-sub {
  margin-top: 0.8rem;
  background-color: #244886;
  border-style: none;
  height: auto;
  color: #ffffff;
  font-size: 1rem;
  width: auto;
  padding: 1rem 2rem;
  margin: 2rem auto;
  margin-bottom: 0;
  font-weight: 500;
  border-radius: 5px;
}

.career-red {
  margin-top: 0.8rem;
  background-color: #862424;
  border-style: none;
  height: auto;
  color: #ffffff;
  font-size: 1rem;
  width: auto;
  padding: 1rem 2rem;
  margin: 2rem auto;
  margin-bottom: 0;
  font-weight: 500;
  border-radius: 5px;
}

.con {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
}

video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

input[type=text].active {
  border: 0px solid #E0E0E0;
}

input[type=email]:focus {
  /* border: 0px; */
  outline: none !important;
}

.contact-form input[type=email] {
  border: 1px solid;
  border-color: #E0E0E0;
  width: 100%;
  max-width: 100%;
  padding: 0.9rem;
  margin-bottom: 0.8rem;
  text-align: left;
}

.contact-form input[type=tel] {
  border: 1px solid;
  border-color: #E0E0E0;
  width: 100%;
  max-width: 100%;
  padding: 0.9rem;
  margin-bottom: 1.3rem;
  text-align: left;
}

.footer-contents {
  min-height: 75vh;
  background-color: #244886;
  padding-top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5rem;
}

.footer-rowone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  margin-bottom: 3vh;
}

.footer-rowtwo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 100%;
  margin-bottom: 5vh;
  margin-top: 5vh;
}

.rowone-a {
  /*width: 20%;
  max-width: 20%;*/
  display: flex;
  justify-content: center;
  align-items: center;
}

.rowtwo-a {
  width: 32%;
  max-width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-right: 5rem;
}

.rowtwo-a p {
  color: #ffffff;
  margin-top: 0;
  /*font-family: "Istok Web";*/
  font-size: 1.053rem;
  font-weight: 300;
  text-align: left;
}

.rowtwolist {
  list-style: none;
}

.rowsecondlist {
  list-style: none;
}

.rowtwolist li {
  color: #ffffff;
  /*font-family: "Istok Web";*/
  font-size: 1rem;
  font-weight: 300;
  text-align: left;
  cursor: pointer;
}

.new-sms-body {
  /*font-family: "Space Grotesk";*/
  color: #010101;
  font-size: 1rem;
  font-weight: 400;
  margin-top: 0.5rem
}

.rowsecondlist li {
  color: #ffffff;
  /*font-family: "Istok Web";*/
  font-size: 1rem;
  font-weight: 300;
  text-align: left;
  cursor: pointer;
}

.rowtwologos {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin-top: 1.6rem;
}

.rowtwo-b {
  width: 18%;
  max-width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: flex-start;
  margin-right: 3.5rem;
}

.rowtwo-c {
  width: 30%;
  max-width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: flex-start;
}

.rowone-b {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rowone-c {
  /*width: 57%;
  max-width: 57%;*/
  display: flex;
  justify-content: center;
  align-items: center;
}

.row-content {
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
  width: 100%;
}

.footer-hr {
  width: 90%;
  border-bottom: 1px solid #ffffff;
}

.sms {
  width: 100%;
  max-width: 100%;
  height: 50vh;
  object-fit: cover;
  margin-bottom: 4rem;
}

.blog-hr {
  width: 100%;
  border-bottom: 0.25px solid #e4d7d7;
  margin-bottom: 1.35rem;
}

.blog-hr {
  width: 95%;
  border-bottom: 1px solid #d1d1d1;
  margin-top: .28rem;
}

.row-content h3 {
  /*font-size: 1.32rem;
  font-weight: 600;*/
   /*font-family: 'Istok Web';*/
  font-size: 1.0rem;
  font-weight: 300; 
  color: #ffffff;
}

.row-content h4 {
  font-size: 0.92rem;
  /*font-family: 'Istok Web';*/
  color: #ffffff;
  font-weight: 300;
}

.blog-slider {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 93%;
  max-width: 93%;
  margin-top: 3rem;
}

.blog-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}

.blog-details p {
  font-size: 0.94rem;
  color: #6A6A6A;
}

.blog-title h4 {
  color: #060336;
  font-size: 1.88rem;
  cursor: pointer;
}

.blog-one {
  width: 48%;
  max-width: 48%;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

}

.blog-two {
  width: 48%;
  max-width: 48%;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.service-header-one {
  margin: 2rem auto;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
}

.head-five {

  font-size: 3rem;
  color: #000000;
  margin-bottom: 3rem;
  font-weight: 700;
  text-align: left
}


.service-header-one h1 {
  color: #244886;
  font-size: 2.75rem;

  font-weight: 700;
  text-align: center;
}



.service-header-one h5 {
  color: #000000;
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 0.55rem;

  text-align: center;
}

.service-header-one h3 {
  color: #244886;
  font-size: 2.5rem;
  font-weight: 700;
  margin-top: 3rem;

  text-align: left;
  margin-bottom: 0;
}
.about-image {
  min-height: 60vh;
  width: 100%;
  max-width: 50%;
  background-image: url("Assets/sent.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain; /* Ensure the entire image is shown */
  margin-top: 1.2rem;
}



.location-dot {
  margin-right: 0.5rem;
  font-size: 1.75rem;
}

.about-text {
  /* min-height: 70vh; */
  width: 47%;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 0%;
  /* padding: 1rem; */
}

.about-text h2 {
  color: #244886;
  font-size: 2.45rem;
}

.about-text p {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 0;
}

.abt {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 0;
  width: 100%;
}

.about-list {
  margin-top: 1.25rem;
  list-style: none;
}

.about-list li {
  color: #010101;
  font-size: 1.2rem;
  margin-bottom: 0.75rem;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.choose-list li {
  color: #010101;
  font-size: 1.2rem;
  margin-bottom: 0.75rem;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.page-one {
  position: relative;
  /* Make sure the container is positioned */
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  margin-bottom: 2rem;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
  padding-top: 3rem;
}

.page-one::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("Assets/back.jpg");
  background-position: center !important;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  /* Ensure the background is behind the content */
}


.paid-one {
  display: none;
}

.abouts-page-one {
  display: flex;
  flex-direction: column;
  padding-top: 3.25rem;
  align-items: left;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  background-color: #F7F6F7;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 4.5rem;
}

.abouts-page-two {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  background-color: #ffffff;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 4.5rem;
  padding-top: 3.5rem;
}

.abouts-page-three {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  background-color: #ffffff;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 4.5rem;
  padding-top: 3.5rem;
}

.abouts-page-thre {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  background-color: #ffffff;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 4.5rem;
  padding-top: 3.5rem;
}

.abouts-page-four {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  background-color: #ffffff;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 4.5rem;
  padding-top: 3.5rem;
}

.aptwo-image {
  width: 47%;
  max-width: 50%;
  background-color: #ffffff;
  background-image: url("Assets/easyset.png");
  background-position: center !important;
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 50vh;
  border-radius: 6px;
}
.getInitt{
font-size: 1.7em;
font-weight: 600;
margin-left: 4rem;
margin-right: 4em;
text-align: center;
}
.getInit{
  font-size: 2.6em;
  font-weight: 700;
  color: #244886;
  }
.aptfour-image {
  width: 47%;
  max-width: 50%;
  background-color: #ffffff;
  background-image: url("Assets/competitive.png");
  background-position: center !important;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 50vh;
  border-radius: 6px;
}

.head-four {
  font-size: 1.13rem;
  color: #001833;
  margin-bottom: 1rem;
  /*padding-left: 0.75rem;*/
  font-weight: 800
}

.apthree-image {
  width: 47%;
  max-width: 50%;
  background-color: #ffffff;
  background-image: url("Assets/deliveryrate.jpg");
  background-position: center !important;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 50vh;
  border-radius: 6px;
}

.file-divs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
}

.file-upload {
  font-size: 0.7rem;
  font-weight: 500;
  margin-top: 0;
}

.file-uploads {
  color: #6A6A6A;

  font-size: 0.75rem;
  font-weight: 500;
}

.aptwo-contents {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: left;
  width: 47%;
  max-width: 50%;
  margin-top: 0;
  min-height: 50vh;
}

.aptfour-contents {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: left;
  width: 47%;
  max-width: 50%;
  margin-top: 0;
  min-height: 50vh;
  padding: 0;
}

.apthree-contents {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: left;
  width: 47%;
  max-width: 50%;
  margin-top: 0;
  min-height: 50vh;
}

.home-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 60%;
  max-width: 65%;
  /* color: #083a8f; */
  min-height: 30rem;
}

.with-blur-backdrops {
  -webkit-backdrop-filter: blur(0.85px);
  backdrop-filter: blur(0.85px);
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
}

.nav-logo-container {
  width: 15%;
  max-width: 18%;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  /* background-color: red; */
}

.serve-image {
  height: 7rem;
  width: 40%;
  max-width: 50%;
  margin-bottom: .75rem;
  /* margin: 0px auto; */
  align-self: center;
  border-radius: 15%;
}


.service-one h3 {
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
  /* text-align: center; */
}

/* .about-text p {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 1.25rem;
} */



.service-two h3 {
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
  /* text-align: center; */
}

.service-three h3 {
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.blog-header {
  margin-top: 4.5rem;
  color: #244886;
  font-size: 2.45rem;
  font-weight: 700
}

.second-blog-header {
  margin-top: 1.5rem;
  font-size: 1rem;
  color: #6A6A6A;
  font-weight: 400
}

.with-blur-backdrops p {
  color: #6B6B6B;
  font-size: 0.9rem;
  font-weight: 700;
  margin-bottom: 0.85rem;

}

.service-one {
  display: flex;
  flex-direction: column;
  width: 30%;
  max-width: 100%;
  background-color: #244886;
  min-height: fit-content;
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
  align-items: center;
  justify-content: space-between;
}

.service-two {
  display: flex;
  flex-direction: column;
  width: 30%;
  max-width: 100%;
  background-color: #244886;
  min-height: fit-content;
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.service-three {
  display: flex;
  flex-direction: column;
  width: 30%;
  max-width: 100%;
  background-color: #244886;
  min-height: fit-content;
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
  align-items: center;
  justify-content: space-between;
  text-align: center;
}


.service-rows {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.service-link {
  text-decoration: none;
  width: 100%;
}

.private-head {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1.25rem;
}

.private-head-two {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  margin-top: 2.75rem;
}

.private-heads {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  margin-top: 1.75rem;
}

.private-update {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 3rem;
  margin-top: 0rem;
}

.private-updates {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
  margin-top: 0rem;
}

.private-body {
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 0.65rem;
}


.private-overlist {
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

.private-list {
  list-style: none;
  margin-left: 1.5rem;
  margin-bottom: 1.5rem;
}

.private-li {
  list-style: none;
  margin-left: 1.5rem;
  margin-bottom: 1.5rem;
}

.private-li li {
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 0.15rem;
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
}

.private-list li {
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 0.15rem;
  display: flex;
  align-items: center;
}

.private-list-mini {
  list-style: none;
  margin-left: 1.5rem;
  margin-bottom: 0rem;
}

.private-list-mini li {
  font-size: 0.95rem;
  font-weight: 400;
  margin-bottom: 0.15rem;
  display: flex;
  align-items: center;
}

.privacy-contents {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left !important;
  min-height: 100vh;
  width: 100%;
  padding: 4.5rem;
  padding-top: 3rem;
  padding-bottom: 5rem;
}

.home-text h4 {
  font-size: 1rem;
  color: #b3bcee;
  font-weight: 600;
}

.home-image {
  width: 48%;
  max-width: 50%;
  min-height: 30rem;
  background-color: #ffffff;
  background-image: url("Assets/sec.jpg");
  background-position: center !important;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 0;
}

.home-text h1 {
  margin-top: 1rem;
  font-size: 2.45rem;

}

.hoht {
  margin-top: 3rem;
  font-size: 3.05rem;

  color: #083a8f
}

.hohta {
  margin-top: 1rem;
  font-size: 1.55rem;
  font-weight: 700;
  color: #000;
  background-color: #fff;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
}

.home-text p {
  margin-top: 1rem;
  margin-bottom: 2rem;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 500;
}

.navbar-menu-container {
  display: none;
}

.navbar-links-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 38%;
}

.contact-button {
  padding: 0.9rem 1.2rem;
  background-color: white;
  color: #010101;
  border: 0.75px solid #0E0E0E;
  border-radius: 5px;
  font-size: 1.13rem;
  cursor: pointer;
  font-weight: 500;
  transition: 0.2s;
  height: 3rem;
  display: flex;
  white-space: nowrap;
  margin: 1rem auto;
}

.conta {
  padding: 1rem 3rem;
  background-color: #244886;
  /* opacity: 5; */
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 1.5rem;
  cursor: pointer;
  font-weight: 700;
  transition: 0.2s;
  /* height: 3rem; */
  display: flex;
  white-space: nowrap;
  margin: 1rem auto;
}

.apply-button {
  padding: 0.9rem 1.8rem;
  background-color: #244886;
  color: #ffffff;
  border-radius: 5px;
  font-size: 0.88rem;
  cursor: pointer;

  font-weight: 500;
  transition: 0.2s;
  display: flex;
  white-space: nowrap;
  border-style: none;
}

.contact-button-active {
  padding: 0.9rem 1.2rem;
  border: 0.75px solid #0E0E0E;
  border-radius: 5px;
  font-size: 1.13rem;
  cursor: pointer;
  font-weight: 500;
  transition: 0.2s;
  height: 3rem;
  display: flex;
  white-space: nowrap;
  background-color: #376dca;
  color: #ffffff;
}

.dummy-button {
  padding: .3rem .9rem;
  background-color: rgb(0, 0, 0);
  color: #ffffff;
  border-style: none;
  border-radius: 10px;
  font-size: .75rem;
  font-weight: 500;
  transition: 0.2s;
  /* height: 2rem; */
  display: flex;
  white-space: nowrap;
}

.service-button {
  padding: 0.9rem 1.2rem;
  background-color: #244886;
  color: #ffffff;
  border: 0.75px solid #ffffff;
  border-radius: 5px;
  font-size: 1.13rem;
  cursor: pointer;
  font-weight: 500;
  transition: 0.2s;
  height: 3rem;
  display: flex;
  white-space: nowrap;
  width: fit-content;
}

.writer-two {
  color: #000000;
}

.service-button-clear {
  padding: 0.9rem 1.2rem;
  background-color: #ffffff;
  color: #000000;
  border: 0.75px solid #000000;
  border-radius: 5px;
  font-size: 1.13rem;
  cursor: pointer;
  font-weight: 500;
  transition: 0.2s;
  height: 3rem;
  display: flex;
  white-space: nowrap;
  width: fit-content;
}

.about-button {
  padding: 0.9rem 1.2rem;
  background-color: white;
  color: #010101;
  border: 0.75px solid #0E0E0E;
  border-radius: 5px;
  font-size: 1.13rem;
  cursor: pointer;
  font-weight: 500;
  transition: 0.2s;
  height: 3rem;
  width: fit-content;
  display: flex;
  white-space: nowrap;
  margin-top: 1rem;
}

.about-button:hover {
  background-color: #376dca;
  color: #ffffff;
}


.about-butt {
  padding: 0.9rem 1.2rem;
  background-color: white;
  color: #010101;
  border: 0.75px solid #0E0E0E;
  border-radius: 5px;
  font-size: 1.13rem;
  cursor: pointer;
  font-weight: 500;
  transition: 0.2s;
  height: 3rem;
  width: fit-content;
  display: flex;
  white-space: nowrap;
  margin-top: 1rem;
}

.about-butt:hover {
  background-color: #376dca;
  color: #ffffff;
}

.service-button:hover {
  background-color: #376dca;
  color: #ffffff;
}

.service-button-clear:hover {
  background-color: #000000;
  color: #ffffff;
  border: 0.75px solid #ffffff;
}

.apply-button:hover {
  background-color: #ffffff;
  color: #000000;
  border: 0.75px solid #000000;
}

.header-one {
  margin-top: 2rem;
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.header-one h2 {
  font-size: 2.45rem;
  width: 100%;
  text-align: center;
}

.two-body {
  font-size: .88rem;

  color: #001833;
  margin-bottom: 1rem;
  /*padding-left: 0.75rem;*/
}

.overlay-body {
  text-align: center !important;
  margin-top: 0.5rem;
  margin-bottom: 0.65rem;
  font-size: .9rem;
  font-weight: 400;

  width: 100%;
  max-width: 100%;
}

.overlay-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: rgb(0, 0, 0);
  z-index: 11;
  padding: 2rem;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  background-color: #ffffff;
  border-radius: 0;
  box-shadow: 0px 4px 4px #00000040;
  height: 22rem;
  width: 27rem;
}

.slider {
  display: flex;
  justify-content: space-between;
  
  width: 100%;
  max-width: 100%;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.overlay-background {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 8;
}

.service-slider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width: 100%;
  max-width: 100%;
  margin-top: 2.75rem;
  margin-bottom: 3.25rem;
  cursor: pointer
}

.expert-slider {
  display: flex;
  justify-content: center;
  column-gap: 20px;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin-top: 3rem;
  margin-bottom: 0;
}

.career-input {
  border: 0.25px solid #828282;
  border-radius: 10px;
  width: 100%;
  height: 3rem;
  margin-top: 0.5rem;
  padding: 1rem;
  margin-bottom: 1rem
}

.expert-image-one {
  width: 100%;
  max-width: 100%;
  min-height: 40vh;
  background-color: #ffffff;
  background-image: url("Assets/Femi.jpg");
  background-position: center !important;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
}

.expert-image-two {
  width: 100%;
  max-width: 100%;
  min-height: 40vh;
  background-color: #ffffff;
  background-image: url("Assets/fidel.jpg");
  /*background-position: center !important;*/
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
}

.rowtwo-background {
  height: 19vh;
  width: 100%;
  max-width: 100%;
  background-image: url("Assets/darklogo.png");
  background-position: center !important;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 0;
  margin-bottom: 0;
}

.expert-image-three {
  width: 100%;
  max-width: 100%;
  min-height: 40vh;
  background-color: #4c9bca;
  background-image: url("Assets/expert3.jpg");
  background-position: center !important;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 12px;
}

.expert-one {
  width: 31%;
  max-width: 31%;
  background-color: #ffffff;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.expert-two {
  width: 31%;
  max-width: 31%;
  background-color: #ffffff;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 768px){
  .expert-slider > div{
    width: 33.3%;
  }
}

.career-label {
  /*font-family: "IBM Plex Sans";*/
  font-size: 0.88rem;
  color: #010101;
  font-weight: 600;
  margin-bottom: 0.5rem
}

.career-labels {
  /*font-family: "IBM Plex Sans";*/
  font-size: 0.88rem;
  color: #010101;
  font-weight: 600;
  margin-bottom: 0.5rem;
  margin-left: .5rem;
}

.expert-three {
  width: 31%;
  max-width: 31%;
  background-color: #ffffff;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.expert-info {
  display: flex;
  width: 80%;
  max-width: 100%;
  align-items: center;
  justify-content: space-between;
  /* align-items: center; */
  margin-top: 1rem;
}

.expert-content {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  width: 75%;
  max-width: 75%;
}

.expert-info h3 {
  color: #060336;
  font-size: 1.25rem;
}

.expert-info h5 {
  color: #6A6A6A;
  font-size: 1rem;
  /*font-family: 'Space Grotesk';*/
  font-weight: 400;
}

.expert-contact {
  display: flex;
  justify-content: center;
  width: 15%;
  max-width: 30%;
}

.unit {
  display: flex;
  flex-direction: column;
  width: 23%;
  max-width: 25%;
}

.service-unit {
  display: flex;
  flex-direction: column;
  width: 30%;
  max-width: 30%;
}

.choose-page {
  margin-top: 3rem;
  margin-bottom: 6rem;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.expert-page {
  min-height: 25vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
}

.expert-page h2 {
  color: #244886;
  font-size: 2.45rem;
  font-weight: 700;
  margin-bottom: 0.75rem;
}

.expert-page h5 {
  color: #000000;
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 1rem;

  width: 60%;
  max-width: 60%;
  text-align: center;
}

.ebebe {
  color: #000000;
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 1rem;

  width: 60%;
  max-width: 60%;
  text-align: center;
}

.expert-hid {
  color: #000000;
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 1rem;

  text-align: center;
  min-width: 100%;
  max-width: 100%;
  text-align: left !important;
}

.expert-page p {
  color: #6A6A6A;
  width: 70%;
  max-width: 70%;
  text-align: center;
}

.left-choose {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 48%;
  max-width: 48%;
}

.choose-page h2 {
  color: #244886;
  font-size: 2.45rem;
  margin-bottom: 2.5rem;
  font-weight: 700;
  text-align: center;
}

.left-choose p {
  font-size: 1.2rem;
  color: #000000;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.right-choose {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;
  width: 48%;
  max-width: 48%;
  /* margin-top: 2rem; */
  /* background-color: red; */
}

.choose-row {
  width: 100%;
  max-width: 100%;
  display: flex;
}

.service-list {
  list-style: none;
}

.choose-list {
  list-style: none;
}

/* .choose-list li {
  font-size: 1.75rem;
  color: #000000;
  font-weight: 600;
} */

.service-list li {
  color: #2F2F2F;
  font-weight: 500;
  margin-bottom: 0.35rem;
  font-size: 0.8rem;

  justify-self: center;
  align-self: center;
}

.serve-list {
  list-style: none;
}

.serve-list li {
  color: #000;
  font-weight: 400;
  margin-bottom: 0.35rem;
  font-size: 1.0rem;

  justify-self: center;
  align-self: center;
}

.serve-list-white {
  list-style: none;
  margin-bottom: 1.5rem;
  color: #b3bcee
}

.serve-list-white li {
  color: #000000;
  font-weight: 400;
  margin-bottom: 0.35rem;
  font-size: 0.9rem;

  justify-self: center;
  align-self: center;
}

.unit h3 {
  margin-top: 1.25rem;
  font-size: 2rem;
  color: #244886;
  text-align: center;
  font-weight: 600;
  line-height: 1.0;
}

.unit p {
  margin-top: 1.25rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: #000000;
  text-align: center;
}

.contact-button:hover {
  background-color: #376dca;
  color: #ffffff;
}

.navbar-cart-icon {
  font-size: 1.15rem;
}

.navbar-menu-container svg {
  font-size: 1.5rem;
  cursor: pointer;
}

.team-work {
  width: 35%;
  margin-bottom: 1rem;
  border-radius: 5px
}

.home-banner-container {
  position: relative;
  display: flex;
  padding-top: 3rem;
}


.home-text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.primary-heading {
  font-size: clamp(2rem, 5vw, 4rem);
  color: #4c4c4c;
  /* line-height: 5rem; */
  max-width: 600px;
}

.primary-text {
  font-size: clamp(1rem, 3vw, 1.5rem);
  max-width: 500px;
  color: #6a6a6a;
  margin: 1.5rem 0rem;
}

.secondary-button {
  padding: 1rem 2.5rem;
  background-color: #fe9e0d;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.secondary-button svg {
  margin-left: 0.75rem;
  font-size: 1.5rem;
}

.secondary-button:hover {
  background-color: #e48f0f;
}

.about-section-container {
  margin-top: 20rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.navbar-links-container a {
  padding: 0 .95rem 0 .95rem;
  text-decoration: none;
  color: black;
  font-size: 1rem;
  font-weight: 500;
}

.navbar-links-container a:hover {
  color: #376dca;
}

.navbar-links-container a.active {
  color: #376dca !important;
}

.service-page-one {
  display: flex;
  flex-direction: column;
  padding-top: 2.9rem;
  align-items: left;
  justify-content: flex-start;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 1rem;
  background-color: #F7F6F7;
}

.first-header {

  font-size: 2.25rem;
  color: #001833;
  margin-bottom: 1.65rem;
}

.first-list {
  list-style: none;
  width: 100%;
  max-width: 100%;
}

.head-first {

  font-size: 1.5rem;
  color: #001833;
  margin-top: 2rem;
  margin-bottom: .75rem;
}

.body-first {

  font-size: 1rem;
  color: #001833;
}

.first-list li {

  font-size: 1rem;
  font-weight: 500;
  margin-bottom: .65rem;
}

.second-header {

  font-size: 1.5rem;
  color: #001833;
  margin-bottom: 1rem;
}

.third-header {

  font-size: 1.125rem;
  color: #000000;
  margin-bottom: 1.75rem;
}

.check-sharp {
  margin-right: .75rem;
  font-size: 2rem;
}

.fourth-header {

  font-size: 1.063rem;
  color: #D4D4D4;
  margin-bottom: 0.4rem;
}

.head-six {

  font-size: 1.5rem;
  color: #001833;
}

.head-seven {

  font-size: 1rem;
  color: #B4B0B0;
  margin-bottom: .5rem;
}

.head-eight {

  font-size: 1rem;
  color: #001833;
}

.fifth-header {

  font-size: 1.063rem;
  color: #001833;
  margin-bottom: 0.4rem;
}


.small-row {
  display: flex;
  justify-content: flex-start;
  align-items: left;
  margin-bottom: .5rem;
}

.small-col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
}

.careers-page-one {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 3.75rem;
  margin-right: 3.75rem;
  margin-bottom: 4.5rem;
  margin-top: 3.25rem;
}

.careers-page-tone {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 3.75rem;
  margin-right: 3.75rem;
  margin-bottom: 3.5rem;
  margin-top: 2.75rem;
}

.career-col {
  display: flex;
  flex-direction: column;
  width: 30%;
  max-width: 32%;
}

.career-col label {
  /*font-family: "poppins";*/
  font-size: 0.75rem;
  color: rgb(0, 0, 0)
}

.career-rows {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  margin-bottom: 3rem;
}

.career-form {
  margin-left: 6rem;
  margin-right: 4rem;
  margin-bottom: 5rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.writer {
  /*font-family: "Space Grotesk";*/
  color: #244886;
  font-size: 0.88rem;
  margin-left: 0.5rem;
  font-weight: 500
}

.writes {
  /*font-family: "Space Grotesk";*/
  color: #1d3157;
  font-size: 1.1rem;
  font-weight: 600;
}

.writes-two {
  /*font-family: "Space Grotesk";*/
  color: #010101;
  font-size: 0.88rem;
  font-weight: 500
}

.head-three {

  font-size: 2.25rem;
  color: #001833;
  margin-bottom: 1rem;
  font-weight: 700;
}

.careers-page-two {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 3.5rem;
  margin-right: 3.5rem;
  margin-bottom: 4.5rem;
  margin-top: 0.5rem;
}

.careers-page-three {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 4rem;
  margin-right: 4rem;
  margin-bottom: 4.5rem;
  margin-top: 0.5rem;
}

.role-one {
  display: flex;
  justify-content: space-between;
  align-content: center;
  min-height: 20vh;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
  width: 100%;
  max-width: 100%;
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 2.5rem;
}

.header-sam {

  font-size: 2.5rem;
  color: #244886;
  margin-bottom: 1rem
}

.page-one-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  width: 49%;
  max-width: 49%;
  min-height: 50vh;
}

.first-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  width: 49%;
  max-width: 49%;
  min-height: 50vh;
  align-self: flex-start;
  margin-right: 5rem;
  margin-left: 2rem;
}

.second-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  width: 33%;
  max-width: 49%;
  min-height: 50vh;
  padding: 2rem;
  background-color: #F9F9F9;
  align-self: flex-start;
}

.cpt-side-a {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: flex-start;
  width: 45%;
  max-width: 45%;
}

.cpt-side-b {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  width: 50%;
  max-width: 55%;
}

.facebook {
  font-size: 2.25rem;
  color: #ffffff;
  margin-right: 1.25rem
}

.linkedin {
  font-size: 2.35rem;
  color: #ffffff;
  margin-right: 1.25rem
}

.twitter {
  font-size: 2rem;
  color: #ffffff;
  margin-right: 1.25rem
}

.whatsapp {
  font-size: 2.2rem;
  color: #ffffff;
  margin-right: 1.25rem
}

.login-form {
  min-height: fit-content;
  width: 35%;
  max-width: 50%;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.log-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 75px;
  background-color: transparent;
  background-color: white;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
  width: 100%;
  /* margin-bottom: 10rem; */
}

.cpt-bone-one {
  width: 45%;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.cpt-bone-one img {
  margin-bottom: 0;
}

.cpt-bone-two img {
  margin-bottom: 0;
}

.cpt-bone-two {
  width: 45%;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.cpt-btwo {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 1rem;
}

.cpt-bone {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.page-one-image {
  max-width: 47%;
  width: 48%;
  min-height: 50vh;
  background-image: url("Assets/team.jpg");
  background-position: center !important;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
}

.blog-page {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: flex-start;
  width: 60%;
  max-width: 55%;
  min-height: 80vh;
  padding-bottom: 2.5rem;
  margin: 0rem auto;
}

.blog-write {
  font-weight: 400;
  font-size: 1.2rem;
}

.author-div {
  display: flex;
  justify-content: flex-start;
  align-items: left;
  margin-bottom: 1.35rem;
}

.blog-page-one {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  align-items: left;
  justify-content: flex-start;
  margin-left: 4rem;
  margin-right: 4rem;
  margin-bottom: 1rem;
  border: none;
}

.blogs-page-one {
  display: flex;
  flex-direction: column;
  margin-top: 0;
  align-items: center;
  justify-content: flex-start;
  margin-left: 4rem;
  margin-right: 4rem;
  margin-bottom: 1.5rem;
  padding-bottom: 2rem;
  padding-top: 1rem;
  border-bottom: 0.25px solid #ebe3e3;
  /* padding: 0.5rem; */
  /* background-color: red; */
}

.blogs-page-one-end {
  display: flex;
  flex-direction: column;
  margin-top: 2.25rem;
  align-items: center;
  justify-content: flex-start;
  margin-left: 4rem;
  margin-right: 4rem;
  margin-bottom: 4.5rem;
  padding-bottom: 2rem;
  /* border-bottom: 1.5px solid #000000; */
}

.blog-header-one {
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
}

.blog-header-one h5 {
  /*font-family: Roboto;*/
  font-size: 1.15rem;
  color: #010101;
  font-weight: 300
}

.service-page-two {
  display: flex;
  justify-content: space-between;
  margin-left: 3rem;
  margin-right: 3rem;
  margin-bottom: 1rem;
  background-color: #fff;
  min-height: fit-content;
  padding: 2rem;
  /*border-top: 1px solid #000;
  border-bottom: 1px solid #000;*/
}
.thebulk{
  width: 50%;
}
.serveimg-two {
  display: none;
}

.service-page-four {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  margin-left: 3rem;
  margin-right: 3rem;
  margin-bottom: 3rem;
  background-color: #244886;
  min-height: fit-content;
  padding: 2rem;
}

.service-header {

  font-size: 2.5rem;
  color: #244886;
  margin-bottom: .5rem
}

.service-header-two {

  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0;
}

.header-sam-p {

  font-size: 1.125rem;
  font-weight: 500;
  color: #000000
}

.io-mail {
  color: white;
  font-size: 2.25rem
}

.io-mai {
  color: white;
  font-size: 2.25rem
}

.io-mails {
  color: white;
  font-size: 2.75rem
}


.service-page-three {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  margin-left: 3rem;
  margin-right: 3rem;
  min-height: fit-content;
  padding: 2rem;
  background-color: #fff;
  margin-bottom: 1rem;
}

.shortCodes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.vassa{
  width: 45%;
}
.shortimg{
  width: 45%;
}
.menusclass-two {
  display: none;
}
.menusclass-one {
  width: 50%;
  /*height: 100%;*/
}
.ussdmenus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.ussdmenus-text {
  width: 50%;
}

.blog-one-row {
  display: flex;
  width: 100%;
  column-gap: 20px;
}

.blog-one-row > div:last-child{
  width: 40%;
  display: flex; align-items: center;
}
.blog-one-row > div:last-child img{
  width: 100%;
}

.last-bor {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.2rem;
}

.last-bor-one {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  max-width: 100%;
}

.plus-circle {
  margin-right: 1rem;
  font-size: 2rem;
  margin-bottom: 2rem
}

.last-bor-two {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  justify-content: flex-end;
  align-items: flex-end;
  width: 20%;
}

.file-div {
  height: 8rem;
  width: 100%;
  border: 2.5px dashed #D0D5DD;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  padding: 0.75rem;
  align-items: center;
}

.about-contents {
  /* min-height: 80vh; */
  margin-top: 3rem;
  background-color: #F7F6F7;
  padding-top: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.about-column {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  background-color: #F7F6F7;
  padding-left: 3.5rem;
  padding-right: 3.5rem;

}

input[type=file] {
  width: 100%;
  height: inherit;
  display: none;
}

.eli {
  width: 2.5rem;
  height: 2.65rem;
}

.bor-content {
  display: flex;
  flex-direction: column;
  width: 73%;
  max-width: 75%;
  min-height: fit-content;
}

.bor-content-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.15rem;
}

.bor-con {
  display: flex;
  justify-content: flex-start;
  align-items: left;

}

.service-header-one h2 {
  color: #244886;
  font-size: 2.45rem;
  margin-top: 0.75rem;

  text-align: center;
}

.bor-image {
  /*max-width: 18%;
  width: 25%;
  height: 13vh;*/
  /* background-image: url("Assets/lappy.png"); */
  /* background-position: center !important; */
  /* background-repeat: no-repeat; */
  /* background-size: contain; */
}

.vas {

  font-size: 1.75rem;
  font-weight: 700;
  color: #000;
  margin-bottom: .5rem
}

.vas-white {

  font-size: 1.75rem;
  font-weight: 700;
  color: #000000;
  margin-bottom: .5rem
}

.vas-white-mini {

  font-size: 1.25rem;
  font-weight: 700;
  color: #000000;
  margin-bottom: .5rem
}

.one-span {
  color: #010101;
  font-size: 1.2rem;
  font-weight: 400
}

.contact {
  width: 17%;
  max-width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.outline-plus {
  color: #244886;
  margin-right: 0.75rem;
  font-weight: 600;
  font-size: 1rem
}

.outline-plu {
  color: #244886;
  margin-right: 0.5rem;
  font-weight: 600;
  font-size: 1.3rem
}

.check-mark {
  color: #244886;
  margin-right: 0.75rem;
  font-weight: 600;
  font-size: 3rem;
}

.blog-imaje {
  object-fit: cover;
  width: 100%;
  height: 50vh;
  max-width: 100%;
  border-radius: "15px";
  cursor: pointer
}

.newly {
  font-size: 1rem;
  color: #244886;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 800px) {

  /* mobile & tablet */
  .navbar-links-container {
    display: none;
  }

  .outline-plus {
    font-size: 1rem
  }

  .check-mark {

    font-size: 1rem;
  }

  .contact {
    display: none;
  }

  .navbar-menu-container {
    display: flex;
  }

  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: fit-content;
    border-bottom: 3px solid #d1d1d1;
    padding: 0.35rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .one-span {
    font-size: 0.55rem;
    margin-top: 0rem;
  }

  .App {
    max-width: 98vw;
  }

  .nav-logo-container {
    width: 45%;
    max-width: 50%;
  }

  .home-contents {
    padding-left: 0rem;
    padding-right: 0rem;
    margin-top: 1.65rem;
  }

  .abouts-page-one {
    padding-top: 1rem;
    align-items: left;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    background-color: #F7F6F7;
    padding-left: 1.1rem;
    padding-right: 1.1rem;
    padding-bottom: 1rem;
  }

  .menusclass-two {
    justify-content: center;
    align-items: center;
    display: none;
    margin-top: 30px;
    width: 100%;
  }

  .menusclass-one {
    width: 100%
  }

  .ussdmenus1{
    flex-direction: column-reverse;
  }

  .home-text h4 {
    font-size: 1.1rem;
    text-align: left !important;
    justify-self: left;
    margin-bottom: 1rem;
  }

  .home-text h1 {
    font-size: 1.8rem;
    width: 100%;
    max-width: 100%;
    margin-bottom: 0rem;
  }

  /*.page-one {
    display: none;
  }*/

  .home-text {
    width: 100%;
    max-width: 100%;
    min-height: 10vh;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .home-image {
    width: 100%;
    max-width: 100%;
    min-height: 15rem;
    margin-top: 1rem;
    margin-bottom: 0;
    background-image: url("Assets/back.jpg");
    background-position: center !important;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .home-text p {
    color: #1f1d1d;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.35rem;
  }

  .shortCodes {
    justify-content: center;
  }
  .vassa{
    width: 100%;
  }
  .shortimg{
    width: 100%;
  }

  .ussdmenus-text {
    width: 100%;
  }

  .blog-imaje {
    height: 25vh;
  }

  .header-one {
    margin-top: 0;
    margin-bottom: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

  }

  .header-one h3 {
    margin-top: 0;
    font-size: 1.5rem;
    width: 90%;
    text-align: center;
  }

  .center-butt {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.8;
  }

  .about-contents {
    margin-top: 1rem;
    padding: 0;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
  }

  .service-contents {
    min-height: 25vh;
    margin-top: 1rem;
    padding: 0;
    margin-left: 1.1rem;
    margin-right: 1.1rem;
    margin-bottom: 3rem;
  }

  .blog-contents {
    min-height: 20vh;
    margin-top: 0;
    background-color: #F7F6FF;
    padding-left: 1.1rem;
    padding-right: 1.1rem;
    padding-bottom: 2.5rem;

  }

  .slider {
    display: flex;
    flex-direction: column;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .unit {
    width: 100%;
    max-width: 100%;
    margin-bottom: 2.5rem;
  }

  .one-body {
    text-align: left;

    font-size: 1.1rem;
    color: #000000;
    margin-bottom: 1rem;
  }

  .about-text {
    min-height: 20vh;
    width: 100%;
    max-width: 100%;
    padding: 1rem;
  }

  .about-text h2 {
    font-size: 2rem;
  }

  .service-header-one h2 {
    font-size: 2rem;
    margin-top: 1.5rem;
    text-align: left;
  }

  .service-header-one h5 {
    color: #000000;
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 0.25rem;

    text-align: center;
  }

  .about-text p {
    font-size: 1rem;
    margin-top: 0.5rem;
  }

  .abt {
    font-size: 1rem;
    margin-top: 0.5rem;

  }

  .about-list {
    margin-top: 1.25rem;
    list-style: none;
  }

  .about-list li {
    font-size: 0.9rem;
    margin-bottom: 0.35rem;
  }

  .abouts-page-two {
    justify-content: flex-start;
    flex-direction: column-reverse;
    padding-left: 1.1rem;
    padding-right: 1.1rem;
    padding-bottom: 2.5rem;
    padding-top: 1.5rem;
  }

  .abouts-page-three {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 100%;
    background-color: #ffffff;
    padding-left: 1.1rem;
    padding-right: 1.1rem;
    padding-bottom: 2.5rem;
    padding-top: 1.5rem;
  }

  .abouts-page-four {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 100%;
    background-color: #ffffff;
    padding-left: 1.1rem;
    padding-right: 1.1rem;
    padding-bottom: 2.5rem;
    padding-top: 1.5rem;
  }

  .service-page-one {
    margin-top: 1.5rem;
    align-items: left;
    margin-left: 1.1rem;
    margin-right: 1.1rem;
    margin-bottom: 1rem;
  }

  .about-button {
    font-size: 0.63rem;
    padding: 0.5rem 0.63rem;
    height: 2rem;
    width: fit-content;
    margin-top: 1rem;
    border-radius: 0;
  }

  .about-image {
    min-height: 15rem;
    width: 100%;
    max-width: 100%;
    margin-top: 1rem;
    height: auto;
  }

  .footer-rowtwo {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    max-width: 100%;
    margin-bottom: 5vh;
    margin-top: 1rem;
  }

  .service-header-one {
    width: 100%;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .service-header-one h1 {
    text-align: left;
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
  }

  .header-one h2 {
    font-size: 1.75rem;
    width: 100%;
    text-align: center;
  }



  .service-slider {
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 2.5rem;
    margin-top: 0;
  }

  .service-one {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
  }

  .service-two {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
  }

  .rowtwo-background {
    height: 10vh;
    width: 100%;
    max-width: 100%;
    background-position: center !important;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 1.5rem;
    margin-bottom: 0;
  }

  .service-three {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
  }

  .choose-page {
    margin-bottom: 0rem;
    min-height: 20vh;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 1.2rem;
    align-items: left;
  }

  .left-choose {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    max-width: 100%;
  }

  .left-choose h2 {
    text-align: left;
    font-size: 1.5rem;
    margin-bottom: 0.7rem;
  }

  .left-choose p {
    font-size: 1.1rem;
    margin-bottom: 0rem;
    text-align: left;
  }

  .right-choose {
    width: 100%;
    max-width: 100%;
    margin-top: 2rem;
    align-items: left;
    justify-content: left;
  }


  .unit h3 {
    font-size: 1.5rem;
    margin-top: 0;
    text-align: center;
  }

  .unit p {
    font-size: 1rem;
    margin-top: 0.25rem;
  }

  .choose-list li {
    font-size: 1rem;
    margin-bottom: 0.75rem;
    font-weight: 700;
    display: flex;
  }

  .choose-list {
    width: 100%;
    max-width: 100;
  }

  .expert-page {
    margin-top: 2rem;
  }

  .expert-page h2 {
    font-size: 2rem;
    margin-bottom: .6rem;
    text-align: center;
  }

  .expert-page p {
    width: 100%;
    max-width: 100%;
    font-size: 0.75rem
  }

  .expert-slider {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 100%;
    margin-top: 2rem;
  }

  .expert-one {
    width: 100%;
    max-width: 100%;
    min-height: 20vh;
    margin-bottom: 2rem;
  }

  .expert-two {
    width: 100%;
    max-width: 100%;
    min-height: 20vh;
    margin-bottom: 2rem;
  }

  .expert-three {
    width: 100%;
    max-width: 100%;
    min-height: 20vh;
    margin-bottom: 0rem;
  }

  .blog-header {
    margin-top: 1.5rem;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
  }

  .head-five {
    font-size: 1.5rem;
    color: #000000;
    margin-bottom: 1.5rem;
    font-weight: 700;
    text-align: left
  }

  .second-blog-header {
    margin-top: 0.75rem;
    font-size: 0.9rem;
    font-weight: 400;
    text-align: center;
  }

  .expert-image-one {
    min-height: 25vh;
  }

  .expert-image-two {
    min-height: 25vh;
  }

  .expert-image-three {
    min-height: 25vh;
    background-color: #4c9bca;
  }

  .blog-slider {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 95%;
    max-width: 95%;
    margin-top: 1rem;
  }

  .blog-one {
    width: 100%;
    max-width: 100%;
    min-height: 20vh;
    margin-bottom: 3rem;
  }

  .blog-two {
    width: 100%;
    max-width: 100%;
    min-height: 20vh;
  }

  .blog-title h4 {
    color: #060336;
    font-size: 1.2rem;
    cursor: pointer;
  }

  .blog-details {
    margin-top: 1rem;
    margin-bottom: 0rem;
  }

  .blog-details p {
    font-size: 0.9rem;
    color: #6A6A6A;
  }

  .rowtwo-b {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
    margin-top: 0;
  }

  .rowtwo-c {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
    margin-top: 1rem;
  }

  .contact-contents h2 {
    font-size: 2.1rem;
    font-weight: 600;
    text-align: center;
  }



  .contact-contents p {
    margin-top: .75rem;
    width: 88%;
    max-width: 100%;
    color: #000000;
    font-size: 0.75rem;
    text-align: left;
  }

  .eliminate-white-mini {
    font-size: 1.1rem;
    font-weight: 500;
    color: #000000;
    margin-bottom: 1rem
  }


  .contact-form {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    width: 88%;
    max-width: 88%;
  }

  .contact-form input[type=text] {
    border: 1px solid;
    border-color: #E0E0E0;
    width: 100%;
    max-width: 100%;
    padding: 0.9rem;
    margin-bottom: 0.8rem;
    text-align: left;
  }

  .expert-info {
    margin-top: 0.75;
  }

  .styled-input-one {
    border: 1px solid;
    border-color: #E0E0E0;
    border-color: #f56a6a;
    width: 20rem;
    /* max-width: 100%; */
    padding: 0.9rem;
    margin-bottom: 0.8rem;
    text-align: left;
  }


  input[type=submit] {
    margin-top: 0.8rem;
    background-color: #244886;
    border-style: none;
    height: 3rem;
    color: #ffffff;
    font-size: 1rem;
    font-weight: 500;
  }

  .career-input {
    border: 0.25px solid #828282;
    border-radius: 3px;
    width: 100%;
    height: 2rem;
    margin-top: 0.25rem;
    padding: 0.8rem;
    margin-bottom: 1rem
  }

  input[type=text].active {
    border: 0px solid #E0E0E0;
  }

  .contact-form input[type=email] {
    border: 1px solid;
    border-color: #E0E0E0;
    width: 100%;
    max-width: 100%;
    padding: 0.9rem;
    margin-bottom: 0.8rem;
    text-align: left;
  }

  .contact-form input[type=tel] {
    border: 1px solid;
    border-color: #E0E0E0;
    width: 100%;
    max-width: 100%;
    padding: 0.9rem;
    margin-bottom: 1.3rem;
    text-align: left;
  }

  .footer-rowone {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 95%;
    max-width: 100%;
    margin-bottom: 0rem;
  }

  .rowone-a {
    width: 100%;
    max-width: 100%;
    display: flex;
    margin-bottom: 1.25rem;
    justify-content: flex-start;
  }

  .footer-hr {
    display: none;
  }

  .bor-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    min-height: 5rem;
  }

  .elip {
    height: 2.5rem;
    width: 12%;
  }

  .rowone-b {
    width: 100%;
    max-width: 100%;
    display: flex;
    margin-bottom: 1.25rem;
    justify-content: flex-start;
  }

  .rowone-c {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  .about-column {

    padding-left: 1rem;
    padding-right: 1rem;
  }
  .row-content {
    display: flex;
    flex-direction: column;
    margin-left: 0.5rem;
    width: 80%;
  }

  .location-dot {
    margin-right: 0.25rem;
    font-size: 0.5rem;
  }

  .row-content h3 {
    font-size: 1.15rem;
    font-weight: 400;
  }

  .row-content h4 {
    font-size: 0.92rem;
    font-weight: 400;
  }

  .io-mail {
    font-size: 1rem;
  }

  .io-mai {
    font-size: 1.4rem;
  }

  .io-mails {
    font-size: 1.2rem;
  }

  .footer-contents {
    min-height: 100vh;
    background-color: #244886;
    padding-top: 2.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 2.5rem;
  }

  .rowtwo-a {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    margin-right: 0rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .rowtwo-a p {
    font-size: 1.1rem
  }

  .rowtwologos {
    display: flex;
    justify-content: flex-start;
    align-items: left;
    width: 100%;
    max-width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .career-label {
    font-size: 0.7rem;
    font-weight: 500;
    margin-bottom: 0.35rem
  }

  .career-labels {
    font-size: 0.7rem;
    font-weight: 500;
    margin-bottom: 0.35rem;
  }

  .service-button {
    padding: 0.5rem 0.6rem;
    font-size: 0.57rem;
    height: 1.8rem;
  }

  .cpt-side-a {
    width: 100%;
    max-width: 100%;
  }

  .service-button-clear {
    padding: 0.5rem 0.6rem;
    font-size: 0.57rem;
    height: 1.8rem;
  }

  .page-one-image {
    max-width: 100%;
    width: 100%;
    min-height: 35vh;
    border-radius: 0px;
    margin-bottom: 0;
  }

  .facebook {
    font-size: 1.45rem;
  }

  .linkedin {
    font-size: 1.55rem;
  }

  .twitter {
    font-size: 1.2rem;
  }

  .whatsapp {
    font-size: 1.4rem;
  }

  .header-sam {
    font-size: 2rem;
    margin-bottom: 1rem
  }

  .header-sam-two {
    font-size: 1.1rem;
    margin-bottom: 1rem
  }

  .header-sam-three {
    font-size: 2rem;
    margin-bottom: 0.5rem
  }

  .header-sam-p {
    font-size: 0.75rem;
    margin-bottom: 1rem
  }

  .aptwo-image {
    width: 100%;
    max-width: 100%;
    min-height: 30vh;
    border-radius: 6px;
  }

  .aptwo-contents {
    width: 100%;
    max-width: 100%;
    margin-top: 0;
    min-height: 10vh;
    margin-bottom: 2rem;
  }

  .header-sam-three-p {
    font-size: 1.1rem;
  }

  .apthree-contents {
    width: 100%;
    max-width: 100%;
    margin-top: 0;
    min-height: 10vh;
    margin-bottom: 2rem;
  }

  .apthree-image {
    width: 100%;
    max-width: 100%;
    min-height: 30vh;
    border-radius: 6px;
  }

  .aptfour-contents {
    width: 100%;
    max-width: 100%;
    margin-top: 0;
    min-height: 10vh;
    margin-bottom: 1.5rem;
  }

  .aptfour-image {
    width: 100%;
    max-width: 100%;
    min-height: 30vh;
    border-radius: 6px;
  }

  .abouts-blank {
    min-height: 10vh;
    background-color: #F7F6F7;
    width: 100%;
  }

  .service-header {
    font-size: 2rem;
    margin-bottom: 0.5rem
  }

  .service-header-two {
    font-size: 1.1rem;
  }

  .service-page-two {
    margin-left: 1.1rem;
    margin-right: 1.1rem;
    min-height: 10vh;
    padding: 1rem 0.5rem;
    margin-top: 0;
    align-items: left;
    text-align: left;
    justify-content: flex-start;
    padding-bottom: 1rem;
    flex-direction: column;
    margin-bottom: 0.3rem;
  }
  .smsimg{
    width: 100% !important;
  }
  .serveimg{
    display: none;
  }
  .thebulk{
    width: 100%;
  }

  .shortCodes1{
    flex-direction: column-reverse;
  }

  .serveimg-two {
    padding: 1rem 1rem;
    display: block;
  }

  .with-blur-backdrops h3 {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 0.3rem;
  }

  .dummy-button {
    padding: .25rem .55rem;
    border-radius: 5px;
    font-size: .38rem;
    font-weight: 500;
    transition: 0.2s;
  }

  .service-page-four {
    margin-left: 1.1rem;
    margin-right: 1.1rem;
    min-height: 10vh;
    padding: 1rem 0.5rem;
    margin-top: 0;
    margin-bottom: 0rem;
  }

  .head-one {
    font-size: 1.75rem;
    margin-bottom: .7rem
  }

  .head-two {
    font-size: 1.3rem;
    margin-bottom: .5rem
  }

  .service-page-three {
    margin-left: 1.1rem;
    margin-right: 1.1rem;
    min-height: 10vh;
    padding: 1rem 0.5rem;
    margin-top: 0;
  }

  .vas {
    font-size: 1.3rem;
    font-weight: 700;
    margin-bottom: .75rem
  }

  .vas-white {
    font-size: 1.3rem;
    font-weight: 700;
    margin-bottom: .75rem
  }

  .vas-white-mini {
    font-size: .7rem;
    font-weight: 700;
    margin-bottom: .75rem
  }

  .eliminate {
    font-size: 1.2rem;
    font-weight: 500;
    color: #000;
    margin-bottom: 1rem
  }

  .eliminate-white {
    font-size: 1.1rem;
    font-weight: 500;
    color: #000000;
    margin-bottom: 1rem
  }

  .blog-page-one {
    margin-top: 1.4rem;
    margin-left: 1.1rem;
    margin-right: 1.1rem;
    margin-bottom: 1.5rem;
  }

  .blog-page {
    width: 80%;
    max-width: 80%;
    min-height: 50vh;
    padding-bottom: 2.5rem;
  }

  .blog-header-one h5 {
    font-size: 1rem;
  }

  .plus-circle {
    font-size: 1rem;
    margin-bottom: 1rem
  }

  .page-one-content {
    width: 100%;
    max-width: 100%;
    min-height: 20vh;
    padding-left: 1.1rem;
    padding-right: 1.1rem;
  }

  .newly {
    font-size: 0.7rem;
  }

  .blogs-page-one {
    margin-top: 0rem;
    align-items: center;
    justify-content: flex-start;
    margin-left: 1.1rem;
    margin-right: 1.1rem;
    margin-bottom: 2rem;
    padding-bottom: 0rem;
    /* border-bottom: 1.5px solid #000000; */
  }

  .blogs-page-one-end {
    margin-top: 0rem;
    align-items: center;
    justify-content: flex-start;
    margin-left: 1.1rem;
    margin-right: 1.1rem;
    margin-bottom: 0rem;
    padding-bottom: 0rem;
    border-bottom: 1.5px solid #000000;
  }

  .new-sms-body {
    font-size: 0.8rem;
    margin-top: 0.25rem
  }

  .bor-image {
    display: none;
  }

  .cpt-side-b {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    width: 100%;
    max-width: 100%;
  }

  .writer {
    font-size: 0.8rem;
    margin-left: 0.5rem;
    margin-right: 0rem;
    margin-top: 0.5rem;
    font-weight: 500
  }

  .writer-two {
    font-size: 0.6rem;
    margin-left: 0.5rem;
  }

  .new-sms {
    font-size: 1rem;
  }

  .service-list li {
    font-weight: 500;
    margin-bottom: 0.35rem;
    font-size: 0.7rem;

  }

  .last-bor {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .header-five {
    /*font-family: "Space Grotesk";*/
    color: #010101;
    font-size: 0.65rem;
    /* margin-left: 0.75rem; */
  }

  .contact-contents {
    min-height: 30vh;
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .careers-page-one {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-left: 0rem;
    margin-right: 0rem;
    margin-bottom: 2rem;
    margin-top: 1.5rem;
  }

  .careers-page-tone {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-left: 0rem;
    margin-right: 0rem;
    margin-bottom: 2rem;
    margin-top: 1.5rem;
    width: 100%;
    max-width: 100%;
  }

  .careers-page-two {
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 1.1rem;
    margin-right: 1.1rem;
    margin-bottom: 2.5rem;
  }

  .head-three {

    font-size: 1.3rem;
    margin-bottom: .5rem;
  }

  .team-work {
    width: 40%;
    margin-bottom: 0rem;
    border-radius: 5px
  }
  .cpt-bone, .cpt-btwo{
    flex-direction: column;
  }
  .cpt-bone > div{
    max-width: 100% !important;
    padding-bottom: 20px;
  }
  .cpt-btwo > div{
    max-width: 100% !important;
    padding-bottom: 40px;
  }
  .cpt-bone-one {
    width: 100%;
  }

  .cpt-bone-two {
    width: 100%;
  }

  .fourth-header {
    font-size: 0.93rem;
    font-weight: 500;
    margin-bottom: 0.25rem;
  }

  .fifth-header {
    font-size: 0.93rem;
    font-weight: 400;
    margin-bottom: 0.35rem;
  }

  .head-four {
    font-size: 1.3rem;

    color: #001833;
    margin-bottom: .5rem;
    padding-left: 0rem;
    font-weight: 800
  }

  .two-body {
    font-size: 1.1rem;

    color: #001833;
    margin-bottom: 0rem;
    padding-left: 0rem
  }

  .careers-page-three {
    margin-left: 1.1rem;
    margin-right: 1.1rem;
    margin-bottom: 0rem;
    margin-top: 0.5rem;
  }

  .role-one {
    min-height: 12vh;
    padding: 0.5rem;
    margin-bottom: 1rem;
    flex-direction: column !important;
  }

  .service-header-one h3 {
    font-size: 1.5rem;
    text-align: left !important;
  }

  .privacy-contents {
    width: 100%;
    padding: 1.5rem;
  }

  .file-div {
    height: 6rem;
    width: 80%;
    border: 2.5px dashed #D0D5DD;
    border-radius: 5px;
    margin-left: .5rem;
  }

  .about-butt {
    display: none;
  }

  .head-six {
    font-size: 1.0rem;
  }

  .head-seven {
    font-size: 1.0rem;
    color: #B4B0B0;
    margin-bottom: .5rem;
  }

  .head-eight {
    font-size: 1.0rem;
    color: #001833;
  }

  .apply-button {
    padding: 10px 20px;
    font-size: 1.0rem;
    justify-content: center;
  }

  .first-div {
    width: 100%;
    max-width: 100%;
    min-height: 20vh;
    align-self: flex-start;
    margin: 0px auto;
    padding-right: 1.1rem;
    padding-left: 1.1rem;
  }

  .second-div {
    width: 80%;
    max-width: 80%;
    min-height: 20vh;
    align-self: flex-start;
    margin: 2.5rem auto;
    padding: 1rem
  }

  .first-header {
    font-size: 1.375rem;
    margin-bottom: .95rem;
  }

  .second-header {
    font-size: 0.91rem;
    color: #001833;
    margin-bottom: .65rem;
  }

  .first-list li {
    font-size: 1rem;
    margin-bottom: .45rem;
  }

  .expert-page h5 {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 0rem;

    width: 80%;
    max-width: 80%;
    text-align: center;
  }

  .head-first {
    font-size: 1.1rem;
    margin-top: 1.2rem;
    margin-bottom: 0.25rem;
  }

  .body-first {
    font-size: 1rem;
  }

  .third-header {

    font-size: 0.98rem;
    color: #000000;
    margin-bottom: 1.05rem;
  }
  .getInitt{
    font-size: 1.1em;

    }
    .getInit{
      font-size: 1.2em;
      color:#244886;
      }

  .career-form {
    margin-left: 0rem;
    margin-right: 0rem;
    margin-bottom: 2.5rem;
    margin-top: 1.5rem;
    width: 90%;
    max-width: 90%;
    margin: 1.5rem auto;
  }

  .career-rows {
    flex-direction: column;
    margin-bottom: 1.5rem;
  }

  .career-col {
    display: flex;
    flex-direction: column;
    width: 95%;
    max-width: 100%;
    margin: 0px auto;
    margin-bottom: 0;
  }

  .serve-list li {
    color: #000;
    font-weight: 400;
    margin-bottom: 0.35rem;
    font-size: 1.1rem;

    justify-self: center;
    align-self: center;
  }

  .serve-list-white li {
    color: #000000;
    font-weight: 400;
    margin-bottom: 0.35rem;
    font-size: 1.1rem;

    justify-self: center;
    align-self: center;
  }


  .career-form input[type=submit] {
    margin-top: 0.8rem;
    background-color: #244886;
    border-style: none;
    height: auto;
    color: #ffffff;
    font-size: 1rem;
    width: auto;
    padding: 1rem 2rem;
    margin: 2rem auto;
    margin-bottom: 0;
    font-weight: 500;
    border-radius: 5px;
  }

  .paid-one {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    margin-bottom: 2rem;
    padding: 0;
  }

  .page-one{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .file-upload {

    font-size: 0.7rem;
    font-weight: 600;
  }

  .with-blur-backdrops p {
    font-size: 0.8rem;
    font-weight: 700;
    margin-top: 0rem;
    margin-bottom: 0.35rem;

  }

  .file-uploads {
    color: #6A6A6A;

    font-size: 0.5rem;
    font-weight: 500;
  }

  .overlay-body {
    text-align: center;
  }

  .memo {
    width: 2.125rem;
    height: 2.25rem;
    margin-bottom: .74rem;
    margin-left: 0.8rem;
  }

  .service-link {
    text-decoration: none;
    width: 100%
  }

  .labow {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  .labe {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 2rem;
  }

  .hoht {
    margin-top: 0rem;
    font-size: 2.15rem;

    color: #000000
  }

  .hohta {
    text-align: left;
    font-size: 1.1em;
    padding: 10px;
  }


  .choose-page h2 {
    color: #244886;
    font-size: 2rem;
    margin-bottom: 0.5rem;
    font-weight: 700;
    text-align: left !important;
    justify-self: left !important;
  }

  .blogs-header {

    font-size: 1.5rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .blog-case-left {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    padding-top: 1.125rem;
    margin: 0px auto;
  }

  .choose-row {
    margin-top: 0.5rem;
    flex-direction: column;
  }

  .last-bor-two {
    display: none;
  }

  .last-bor-one {
    width: 100%;
  }
}

@media screen and (max-width: 350px) {
  /* mobile specific only */



}